import "./CardDetailed.scss";
import React, { useEffect, useState } from "react";
import Chart from ".././components/Chart";

export default function CardDetailed({ companyTicker }) {
  const [quote, setQuote] = useState(null);

  useEffect(() => {
    fetchURL();
  }, []);

  async function fetchURL() {
    // const API_Key = "RLJ0L911Z6IA4895";
    const IEX_Publishable_Token = "pk_2e00de628d144cd7983961e082c54d81";
    const API_Key = "demo";
    // const quoteURL = `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${companyTicker}&apikey=${API_Key}`;
    // const quoteURL = `https://cloud.iexapis.com/stable/stock/${companyTicker}/quote/latestPrice?token=${IEX_Publishable_Token}`;
    const quoteURL = `https://cloud.iexapis.com/stable/stock/${companyTicker}/quote?token=${IEX_Publishable_Token}`;

    const quoteURLResponse = await fetch(quoteURL);
    const quoteURLResponseJSON = await quoteURLResponse.json();
    setQuote(quoteURLResponseJSON);

    console.log(quoteURL);
    console.log(quoteURLResponseJSON);
    console.log("This is quote: " + quote);
  }

  return (
    <>
      <div className="cardDetailed">
        <Chart companyTicker={companyTicker} footer="Previous 90 Day Opening" />
        <div className="stats">
          <ul>
            <li>
              Latest Price: {quote ? "$" + quote["latestPrice"] : "Loading…"}
            </li>
            <li>High</li>
            <li>Low</li>
          </ul>
        </div>
      </div>
    </>
  );
}
