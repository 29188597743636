import "./Chart.scss";
import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { format, parseISO, subDays } from "date-fns";

function CustomTooltip({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip">
        <h4>{format(parseISO(label), "eeee, d MMM, yyyy")}</h4>
        <p>${payload[0].value} USD</p>
      </div>
    );
  }
  return null;
}

export default function Chart({ companyTicker, footer }) {
  const [chartXValues, setChartXValues] = useState([]);
  const [chartYValues, setChartYValues] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);

  useEffect(() => {
    fetchURL();
  }, []);

  const chartData = [];
  for (let num = 90; num >= 0; num--) {
    chartData.push({
      date: chartXValues[num],
      value: chartYValues[num],
    });
  }

  async function fetchURL() {
    const API_Key = "RLJ0L911Z6IA4895";
    const IEX_Publishable_Token = "pk_2e00de628d144cd7983961e082c54d81";
    // const API_Key = "demo";
    const URL = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY_ADJUSTED&symbol=${companyTicker}&outputsize=compact&apikey=${API_Key}`;
    // const URL = `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY_ADJUSTED&symbol=IBM&apikey=demo`;
    // const companyDetailsURL = `https://www.alphavantage.co/query?function=OVERVIEW&symbol=${companyTicker}&apikey=${API_Key}`;
    const companyDetailsURL = `https://cloud.iexapis.com/stable/stock/${companyTicker}/company?token=${IEX_Publishable_Token}`;

    console.log(URL);

    const urlResponse = await fetch(URL);
    const urlResponseJSON = await urlResponse.json();

    const responseChartXValues = Object.keys(
      urlResponseJSON["Time Series (Daily)"]
    );
    const responseChartYValues = Object.values(
      urlResponseJSON["Time Series (Daily)"]
    ).map((value) => value["1. open"]);

    setChartXValues(responseChartXValues);
    setChartYValues(responseChartYValues);

    const companyDetailsURLResponse = await fetch(companyDetailsURL);
    const companyDetailsURLResponseJSON = await companyDetailsURLResponse.json();
    setCompanyDetails(companyDetailsURLResponseJSON);
  }

  return (
    <>
      <div className="contents">
        <div className="chartMeta">
          <div className="logo">
            <img
              src={`https://storage.googleapis.com/iex/api/logos/${companyTicker}.png`}
              alt=""
            />
          </div>
          <div className="companyName">
            <h2>{companyDetails ? companyDetails["symbol"] : "Loading…"}</h2>
            <h4>
              {companyDetails ? companyDetails["companyName"] : "Loading…"}
            </h4>
          </div>
        </div>
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart data={chartData}>
            <defs>
              <linearGradient id="strokeColor" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#B4AFF5" stopOpacity={1} />
                <stop offset="14%" stopColor="#C1BAEF" stopOpacity={1} />
                <stop offset="28%" stopColor="#D8CBE6" stopOpacity={1} />
                <stop offset="42%" stopColor="#DECAD7" stopOpacity={1} />
                <stop offset="56%" stopColor="#E6C6C4" stopOpacity={1} />
                <stop offset="70%" stopColor="#EBC0B1" stopOpacity={1} />
                <stop offset="100%" stopColor="#EBBDA9" stopOpacity={1} />
              </linearGradient>
              {/* <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#2451B7" stopOpacity={0.4} />
                <stop offset="75%" stopColor="#2451B7" stopOpacity={0.05} />
              </linearGradient> */}
              <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#DECAD7" stopOpacity={0.4} />
                <stop offset="75%" stopColor="#EBBDA9" stopOpacity={0.05} />
              </linearGradient>
            </defs>

            <Area
              dataKey="value"
              stroke="url(#strokeColor)"
              fill="url(#color)"
            />

            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              tickFormatter={(str) => {
                const date = parseISO(str);
                if (date.getDate() % 4 === 0 || date.getDate() % 7 === 0) {
                  return format(date, "MMM, d");
                }
                return "";
              }}
            />

            <YAxis
              datakey="value"
              axisLine={false}
              tickLine={false}
              tickCount={8}
              tickFormatter={(number) => `$${number.toFixed(2)}`}
            />

            <Tooltip content={<CustomTooltip />} />

            <CartesianGrid opacity={0.1} vertical={false} />
          </AreaChart>
        </ResponsiveContainer>
        <small>{footer}</small>
        {/* <p>{companyDetails ? companyDetails["Address"] : "Loading…"}</p> */}
      </div>
    </>
  );
}
