import "./Layout.scss";

const Layout = (props) => {
  return (
    <div className="layout">
      <main className="content">{props.children}</main>
    </div>
  );
};

export default Layout;
