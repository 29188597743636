import Layout from ".././components/Layout";
import CardDetailed from ".././components/CardDetailed";
import Label from ".././components/Label";
import "./home.scss";
import React, { useEffect, useState } from "react";

function GreetingMessage() {
  const getGreetingMessage = () => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr >= 4 && curHr <= 11) {
      return "Good morning, Syeef";
    } else if (curHr >= 12 && curHr <= 18) {
      return "Good afternoon, Syeef";
    } else {
      return "Good evening, Syeef";
    }
  };
  return <h2>{`${getGreetingMessage()}`}</h2>;
}

function TheDate() {
  const getDate = () => {
    const today = new Date();
    const day = today.toLocaleDateString("GB-en", { weekday: "long" });
    const month = today.toLocaleDateString("GB-en", { month: "long" });
    const date = today.getDate();

    const formattedDate = day + ", " + month + " " + date;
    return formattedDate;
  };
  return <p className="date">{`${getDate()}`}</p>;
}

function MarketState() {
  const today = new Date();
  const day = today.toLocaleDateString("GB-en", { weekday: "long" });
  if (day === "Saturday" || day === "Sunday") {
    return <Label StyleClassName="closed" text="Markets closed" />;
  } else {
    return <Label StyleClassName="open" text="Markets open" />;
  }
}

export default function Homepage(props) {
  return (
    <>
      <Layout>
        <header>
          <GreetingMessage />
          <div className="meta">
            <TheDate />
            <MarketState />
          </div>
        </header>
        <h2>Your companies</h2>
        {/* <CardDetailed companyTicker="IBM" /> */}
        <CardDetailed companyTicker="NET" />
        <CardDetailed companyTicker="AKAM" />
        <CardDetailed companyTicker="AAPL" />
        {/* <Chart companyTicker="IBM" footer="Previous 90 Day Opening"></Chart> */}

        {/* <Chart companyTicker="NET" footer="Previous 90 Day Opening"></Chart>
        <Chart companyTicker="AKAM" footer="Previous 90 Day Opening"></Chart>
        <Chart companyTicker="AAPL" footer="Previous 90 Day Opening"></Chart> */}
      </Layout>
    </>
  );
}
